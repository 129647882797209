var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-1" },
    [
      _c(
        "b-overlay",
        {
          attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center mt-5" },
                    [
                      _c("base-icon", {
                        attrs: { name: "loading", width: "35", height: "35" },
                      }),
                      _vm._v(" "),
                      _c("p", { attrs: { id: "cancel-label" } }, [
                        _vm._v("Operazione in corso..."),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          !_vm.isLoading
            ? _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "div",
                    { staticStyle: { flex: "1" } },
                    _vm._l(_vm.dtlFlds, function (item, index) {
                      return _c("details-card", {
                        key: index,
                        attrs: {
                          title: index,
                          registryId: _vm.resourceId,
                          addressBookId: item.id || null,
                          header: item.header,
                          edit: item.edit || false,
                          shortcut: item.shortcut,
                          create: item.create || false,
                          fields: item.fields,
                          beForm: _vm.beForm,
                          data: _vm.details,
                          repository: item.repository,
                        },
                        on: { update: _vm.onUpdate, create: _vm.onCreate },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _vm.$store.state.auth.settings.attributables.LISALEX &&
                  _vm.$store.state.auth.settings.attributables.LISALEX === "Y"
                    ? [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "30%",
                              padding: "8px 0 8px 30px",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  position: "sticky",
                                  top: "20px",
                                },
                              },
                              [
                                _c("iframe", {
                                  staticStyle: {
                                    border: "0",
                                    margin: "0",
                                    padding: "0",
                                    "min-height": "500px",
                                  },
                                  attrs: {
                                    src: `https://${_vm.$store.state.auth.config_agency_name}.gaat.lisalex.it/widget/${this.resourceId}?token=${_vm.$store.state.auth.token}`,
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }